.post {
  min-height: calc(100vh - 50px);
  max-width: 1280px;
  margin: 0 auto;
}

.post__path {
  padding: 24px 24px 0;
}

.post__container {
  padding: 24px 24px 48px;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 576px) {
  .post__container {
    flex-direction: column;
    padding: 24px 8px;
  }
}