.global-header {
  z-index: 1;
  background-color: #fff;
  height: 50px;
  box-shadow: 0 0 50px #ccc;
  position: sticky;
  top: 0;
}

.global-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  height: 100%;
  margin: 0 auto;
  width: 1280px;
  max-width: 100%;
}
.global-header__logo {
  display: flex;
  > h1 {
    margin-bottom: 0;
    font-size: 24px;
  }
}

.global-header__search {
  margin-right: auto;
  margin-left: 24px;
  .global-header__search {
    width: 400px;
  }
  .global-header__search-input {
    width: auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .global-header__search {
    width: 240px;
  }
}

@media only screen and (max-width: 576px) {
  .global-header__search {
    width: calc(100% - 85px - 45px - 24px - 10px);
  }
}