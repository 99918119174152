$header_height: 50px;
$footer_height: 60px;

.home {
  min-height: calc(100vh - #{$header_height});
}

.home-main {
  min-height: calc(100vh - #{$header_height} - #{$footer_height});
  max-width: 1280px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: row;
}

.home-footer {
  height: $footer_height;
  padding: 18px 24px;
  text-align: center;
}