.post-creation-tc {
  margin: 24px 0;
}

.post-creation-tc__field {
  width: 120px;
  margin: 0px 5px 12px;
  > label {
    display: block;
    margin-bottom: 4px;
    text-align: center;
  }
}

.post-creation-tc__row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.post-creation-tc__section-title {
  text-align: center;
}

.post-creation-tc__summary {
  text-align: center;
}