.post-main {
  width: calc(100% - 200px - 200px - 24px - 24px);
  margin: 0 24px;
}

.post-main__title {
  background-color: #fff;
  padding: 24px 32px;
  margin-bottom: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: -16px;
    top: 24px;
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-right-color: white;
    border-left: 0;
    border-top-width: 12px;
    border-bottom-width: 12px;
  }

  > h1 {
    margin-bottom: 0;
  }
}

.post-main__content {
  background-color: #fff;
  padding: 24px 32px;
  min-height: 50vh;
  font-size: 14px;
}

.post-main_copyright-disclaimer {
  margin-top: 24px;
  text-align: center;
  color: darkgrey;
  user-select: none;
}

.post-mian__placeholder {
  &.post-main__title-text {
    height: 33px;
    width: 200px;
  }
}

.post-main__content-placeholder > div {
  width: 60%;
  height: 24px;
  margin-bottom: 10px;
  &:first-child {
    width: 90%;
  }
  &:last-child {
    width: 30%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .post-main {
    width: calc(100% - 200px - 24px);
    margin-right: 0;
  }
}

@media only screen and (max-width: 576px) {
  .post-main {
    width: 100%;
    margin: 0;
  }
  .post-main__title::after {
    border: 16px solid transparent;
    border-bottom-color: white;
    border-top: 0;
    border-left-width: 12px;
    border-right-width: 12px;
    top: -16px;
    left: 8px;
  }
}