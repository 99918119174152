.login {
  max-width: 1280px;
  margin: 0 auto;
  padding: 24px;
}

.login-container {
  padding: 24px;
  background-color: white;
}

.login-other-method {
  text-align: center;
}
