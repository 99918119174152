.login-google-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  max-width: 100%;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}