.post-thread {
  &:not(:last-of-type) {
    border-bottom: 1px solid #f2f3f3;
  }

  border: 1px solid transparent;

  &:hover {
    border: 1px solid lightgray;
  }
}

.post-thread__view-reply {
  margin-left: 24px;
  font-size: 13px;
}

.post-thread__replys {
  padding: 8px 0;
  background-color: #f2f3f3;
  .post-thread__replys-loading {
    text-align: center;
    padding: 12px;
  }
}


.post-thread__expand {
  display: flex;
  align-items: center;
  cursor: pointer;

  .post-thread__expand-line {
    display: inline-block;
    width: 32px;
    height: 1px;
    background-color: black;
    margin-right: 8px;
  }
}

.post-thread__replys-fetching-more {
  padding-left: 24px;
}
