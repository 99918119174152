.post-comments__pagination {
  margin-top: 24px;
  text-align: center;
}

.post-comments__textarea-container {
  display: flex;

  .post-comments__textarea-avatar {
    margin-right: 12px;
  }

  .post-comments__textarea {
    width: 100%;
    padding: 12px;
    border: none;
  }
}

.post-comments__textarea-actions {
  padding-left: 48px;
  padding-top: 12px;
  display: flex;
  justify-content: flex-end;
}

.post-comments__textarea-login {
  text-align: center;
}
