@import '~antd/dist/antd.css';

body, html, #root {
  background-color: #f0f2f5;
  font-size: 13px;
}

.app-container {
  transition: right 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  right: 0;
  &.app-container-pushed {
    transition: right 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    right: 240px;
    height: 100vh;
    max-height: -webkit-fill-available;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 50px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

button > i:not(:last-child) {
  margin-right: 8px;
}

@keyframes placeHolderSwept {
  0% {
    background-position: -400px 0
  }

  100% {
    background-position: 400px 0
  }
}

.loading-placeholder {
  animation: placeHolderSwept 1s infinite linear forwards;
  background: #ebebec;
  background-image: linear-gradient(to right, #ebebec 0%, #e0e0e2 20%, #ebebec 40%, #ebebec 100%);
  background-repeat: no-repeat;
}