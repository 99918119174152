.global-side-panel {
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: fixed;
  height: 100vh;
  max-height: -webkit-fill-available;
  top: 0;
  bottom: 0;
  right: -240px;
  width: 240px;
  background-color: #f0f2f5;
  z-index: 2;
  border-left: 1px solid lightgrey;
  overflow: auto;
  padding-top: 24px;

  &.side-panel-opened {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    right: 0;
  }
}

.global-side-panel__user {
  padding: 8px 16px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  > .global-side-panel__user-name {
    margin: 0 auto 0 8px;
  }
  > i:last-child {
    color: darkgrey;
  }
}

.global-side-panel__login {
  display: block;
  text-align: center;
}