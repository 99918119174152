.post-owner {
  background-color: white;
  align-self: flex-start;
  padding: 24px;
  border-radius: 4px;
  width: 200px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.post-owner__user-info {
  text-align: center;
  .post-owner__avatar {
    margin-bottom: 12px;
  }
  .post-owner__ago {
    color: darkgrey;
    &.post-owner__ago-loading {
      width: 100%;
      height: 20px;
    }
  }
}

.post-owner__stats {
  margin: 12px auto 0 auto;
  max-width: 200px;
  font-size: 13px;
  color: #bbb;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 576px) {
  .post-owner {
    width: 100%;
    margin-bottom: 24px;
  }
}