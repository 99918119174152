.post-info-tc {
  margin: 24px 0;
}
.post-info__title {
  text-align: center;
}
.post-info__section-title {
  text-align: center;
}
.post-info-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.post-info-field {
  text-align: center;
  width: 130px;
  margin: 0px 5px 12px;
  background: #fbfcfc;
  padding: 8px 12px;
  border-radius: 12px;
}
.post-info__summary {
  text-align: center;
}