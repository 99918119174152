.post-thread__head {
  padding: 16px 16px 8px;
  background-color: #fdfdfd;
}

.post-thread__head-container {
  display: flex;

  .post-thread__head-avatar {
    margin-right: 12px;
  }

  .post-thread__head-header {
    margin-bottom: 4px;

    .post-thread__head-header-username {
      margin-right: 8px;
      color: #727477;
      font-size: 13px;
    }

    .post-thread__head-header-ago {
      color: #b7b8b8;
      font-size: 12px;
    }
  }

  .post-thread__head-action {
    margin: 8px 0;

    >.post-card__head-interaction {
      margin-right: 8px;
      font-size: 12px;
      color: #6c7a89;
      cursor: pointer;
      opacity: 0.6;
      background: transparent;
      border: none;
      padding: 0;
      &:hover {
        color: black;
        opacity: 1;
      }
    }

    >button {
      color: #6c7a89;
      font-size: 13px;
    }
  }
}

.post-thread__reply {
  margin-left: 32px;
  border-top: 1px solid #f2f3f3;
  padding: 12px 0;
  margin-bottom: 8px;

  .post-thread__reply-textarea-container {
    display: flex;
  }

  .post-thread__reply-textarea-avatar {
    margin-right: 12px;
  }

  .post-thread__reply-textarea {
    width: 100%;
    padding: 12px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #f2f3f3;
    background-color: #f2f3f3;
  }

  .post-thread__reply-textarea-actions {
    padding-left: 48px;
    padding-top: 12px;
    display: flex;
  }
}

.post-thread__reply-login {
  margin-left: 32px;
  border-radius: 3px;
  text-align: center;
  background-color: #f2f3f3;
  padding: 12px 0;
  margin-bottom: 8px;
}
