.post-card {
  width: 100%;
  background-color: white;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;
  border-radius: 6px;
  padding: 12px 20px 20px;
  &:hover {
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
  }
}

.post-card__stats {
  display: flex;
  align-items: center;

  .post-card__stats-avatar {
    margin-right: 10px;
  }

  .post-card__stats-username {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .post-card__stats-ago {
      font-size: 10px;
      color: darkgrey;
    }
  }

  .post-card__stats-icon {
    font-size: 10px;
    color: darkgrey;
    margin-left: 10px;
  }
}

.post-card__divider {
  width: 100%;
  margin: 8px 0;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.post-card__details-title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.post-card__details-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 24px;
}
.post-card__details-category {
  font-size: 10px;
  line-height: 10px;
  padding: 4px 6px;
  border-radius: 4px;
  display: inline-block;
  background-color: #d1d8e0;
  color: #4b6584;
  text-align: center;
  min-width: 55px;
}

.post-card__details-content {
  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
  max-height: 60px;
  line-height: 20px;
  margin: 0;
  color: black;
}
